<template>
  <div class="dashboard">
    <div class="dashboard__container">
      <div class="dashboard__container--body">
        <div class="mb-5" style="width:50%; min-width: 24rem;">
          <div style="left: 0; width: 100%; height: 0; position: relative; padding-bottom: 56.092%;"><iframe src="https://giphy.com/embed/huJmPXfeir5JlpPAx0" style="top: 0; left: 0; width: 100%; height: 100%; position: absolute; border: 0;" allowfullscreen scrolling="no" allow="encrypted-media;"></iframe></div>
        </div>
        <Loader v-if="!shiftInfo || (!userProfile || !userProfile.id)" />
        <div class="dashboard__container--body--col flex" v-if="shiftInfo && eventInfo" style="width:100%;">
          <ShiftCard :eventInfo="eventInfo" :shift="shiftInfo" v-if="userProfile && userProfile.id" />
          <!-- <ShiftCard :shift="shiftInfo"  /> -->
          <div v-if="!userProfile || !userProfile.id" >
            Please <router-link :to="{name: 'loginNew'}">Log In</router-link> and then click the link in your text message again.
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>
  .ep-container {
    margin: 0.5rem 0;
  }
  
</style>

<script>
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
// import ShiftCardFull from '@/components/Shifts/ShiftCardFull.vue'
import ShiftCard from '@/components/Shifts/ShiftCard.vue'

export default {
  name: 'confirm',
  created() {
    this.$store.dispatch('confirmFromId', this.$route.params.id)
  },
  mounted() {
    if (!this.currentUser || !this.currentUser.uid) {
      this.$router.push('/auth/login')
    }
  },
  computed: {
    ...mapState(['assignment', 'userProfile', 'currentUser']),
    eventInfo() {
      return this.assignment.eventInfo
    },
    shiftInfo() {
      return this.assignment.shift
    }
  },
  components: {
    Loader,
    // ShiftCardFull,
    ShiftCard
  },
}
</script>